export default function formatDate(notificationDate) {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    const currentDate = new Date();
    const diffTime = Math.abs(currentDate - notificationDate);
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    const diffHours = Math.floor(diffTime / (1000 * 60 * 60));
    const diffMinutes = Math.floor(diffTime / (1000 * 60));


    if (diffDays === 0) {
        if (diffHours === 0)
            return `${diffMinutes} minutes ago`
        else return `${diffHours} hours ago`
    } else if (diffDays === 1)
        return `Yesterday at ${notificationDate.toLocaleString().split(", ")[1]}`
    else return `${days[notificationDate.getDay()]} at ${notificationDate.toLocaleString().split(", ")[1]}`

    return notificationDate.toLocaleString();
}